import { useState, useEffect } from 'react';
import {Button, Stack, Typography, Divider, Paper,Dialog, DialogTitle, DialogActions, IconButton, DialogContent} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAgents } from '../Utils/fetchData';
import CloseIcon from '@mui/icons-material/Close';

const Home = () => {
  const [agents, setAgents] = useState([]);
  const [id, setId] = useState({
    name: '',
    desc: ''
  });
  const [openFilter, setOpenFilter] = useState(false);

  const fetchData = async () => {
    const res = await getAgents();
    console.log(res.data.data)
    setAgents(res.data.data);
  }

  useEffect(() => {
    fetchData();
  },[])
  
  return (
    <div>
        <Stack direction={'column'}>
            <Typography sx={{
                fontWeight: 'bold',
                fontSize: '30px',
                margin: 2
            }}>
                AGENTS
            </Typography>
            {agents?.map((el) => (
                <div id={el.uuid}>  
                    <Stack component={Paper} sx={{margin: 2, padding: 2, flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'space-evenly'}}>
                        <Stack sx={{marginRight: '20px'}}>
                            <img src={el.bustPortrait} alt="" width="350px" height="350px" />
                        </Stack>
                        
                        <Stack spacing={2} direction={'column'}>
                            <Typography sx={{
                                fontWeight: 'bold',
                                fontSize: '24px'
                            }}>
                                {el.displayName?.toUpperCase()}
                            </Typography>

                            <Stack spacing={1} direction={'row'} sx={{alignItems: 'center'}}>
                                <img src={el.role.displayIcon} alt="" title={el.role.description} width='25px' height='25px' style={{backgroundColor: 'gray', borderRadius: '50%'}} />
                                <Typography sx={{
                                    fontWeight: 'bold',
                                    fontSize: '18px'
                                }}>
                                    {el.role.displayName}
                                </Typography>
                            </Stack>
                            
                            <Typography>
                                "{el.description}"
                            </Typography>

                            <Typography sx={{
                                fontWeight: 'bold'
                            }}>
                                Abilities : 
                            </Typography>
                            <Stack sx={{ flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'space-evenly'}}>
                                <Stack direction={'column'} sx={{alignItems: 'center'}}>
                                    <img onClick={() => {
                                        setId(el.abilities[0]);
                                        setOpenFilter(true);
                                    }} src={el.abilities[0].displayIcon} alt="" title={el.abilities[0].description} width='100px' height='100px' style={{backgroundColor: 'gray', borderRadius: '50%'}} />
                                    <Typography>
                                        {el.abilities[0].displayName}
                                    </Typography>
                                </Stack>
                                <Stack direction={'column'} sx={{alignItems: 'center'}}>
                                    <img onClick={() => {
                                        setId(el.abilities[1]);
                                        setOpenFilter(true);
                                    }} src={el.abilities[1].displayIcon} alt="" title={el.abilities[1].description} width='100px' height='100px' style={{backgroundColor: 'gray', borderRadius: '50%'}} />
                                    <Typography>
                                        {el.abilities[1].displayName}
                                    </Typography>
                                </Stack>
                                <Stack direction={'column'} sx={{alignItems: 'center'}}>
                                    <img onClick={() => {
                                        setId(el.abilities[2]);
                                        setOpenFilter(true);
                                    }} src={el.abilities[2].displayIcon} alt="" title={el.abilities[2].description} width='100px' height='100px' style={{backgroundColor: 'gray', borderRadius: '50%'}} />
                                    <Typography>
                                        {el.abilities[2].displayName}
                                    </Typography>
                                </Stack>
                                <Stack direction={'column'} sx={{alignItems: 'center'}}>
                                    <img onClick={() => {
                                        setId(el.abilities[3]);
                                        setOpenFilter(true);
                                    }} src={el.abilities[3].displayIcon} alt="" title={el.abilities[3].description} width='100px' height='100px' style={{backgroundColor: 'gray', borderRadius: '50%'}} />
                                    <Typography>
                                        {el.abilities[3].displayName}
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Dialog
                                open={openFilter}
                                onClose={() => setOpenFilter(false)}
                                aria-labelledby="filter-dialog"
                                fullWidth
                                maxWidth="md"
                            >
                                <DialogTitle id="filter-dialog">
                                    <Stack direction={'row'} sx={{
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}>
                                        {id?.displayName}
                                        <IconButton
                                            onClick={() => {setOpenFilter(false)}}
                                            component='label'
                                            sx={{
                                                color: 'black',
                                                borderRadius: '50%',
                                                opacity: 1,
                                                '&:hover': {
                                                    opacity: 1,
                                                    color: 'gray',
                                                    transform: 'scale(1.0)',
                                                },
                                            }}
                                            >
                                            <CloseIcon sx={{fontSize: '20px'}} />
                                        </IconButton>
                                    </Stack>
                                </DialogTitle>
                                <DialogContent>
                                    <Stack spacing={2}>
                                        <Typography>
                                            {id?.description}
                                        </Typography>
                                    </Stack>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        sx={{backgroundColor: 'cancel', fontSize: {md: '14px', xs: '12px'}, ':hover': {backgroundColor: 'cancelhover'}}}
                                        onClick={() => {
                                            setOpenFilter(false);
                                        }}
                                    >
                                        Back
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Stack>
                    </Stack>
                </div>
            ))}
        </Stack>
    </div>
  )
};

export default Home;
