import {Button, Stack, Divider, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const Navigate = useNavigate();
  
  return (
    <div>
        <Stack direction={'column'}>
            <Stack direction={'row'} sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                <Stack direction={'row'} sx={{margin: 2}}>
                    <Button onClick={() => {
                        Navigate('/')
                    }}>
                        Agents
                    </Button>
                    <Button onClick={() => {
                        Navigate('/agent-comp')
                    }}>
                        Comp
                    </Button>
                </Stack>
                <Stack sx={{margin: 2}}>
                    <Typography sx={{
                        fontSize: '12px',
                        color: 'grey'
                    }}>
                        Data from <a href='https://valorant-api.com/'>Valorant-API</a>
                    </Typography>
                </Stack>
            </Stack>

            <Divider />
        </Stack>
    </div>
  )
};

export default Navbar;
