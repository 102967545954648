import axios from 'axios';

const API = axios.create({
    baseURL: 'https://valorant-api.com/',
});

export const getAgents = async () => {
    const res = await API.get('v1/agents?isPlayableCharacter=true');

    return res;
}