import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import {
  Home,
  Picker
} from './Pages'
import { Navbar } from './Components';

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [{
      path: '/',
      element: <Home />
    },{
      path: '/agent-comp',
      element: <Picker />
    }]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
