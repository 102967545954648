import { useState, useEffect } from 'react';
import {Button, Stack, Typography, Grid, Dialog, DialogTitle, DialogActions, IconButton, DialogContent, Container} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAgents } from '../Utils/fetchData';
import CloseIcon from '@mui/icons-material/Close';

const Picker = () => {
  const [agents, setAgents] = useState([]);
  const [picked, setPicked] = useState([]);
  const [revealOne, setOne] = useState(false);
  const [revealTwo, setTwo] = useState(false);
  const [revealThree, setThree] = useState(false);
  const [revealFour, setFour] = useState(false);
  const [revealFive, setFive] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [id, setId] = useState({});

  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  const fetchData = async () => {
    const res = await getAgents();
    setAgents(res.data.data);
  }

  const rando = () => {
    const random = shuffle(agents);

    setOne(false);
    setTwo(false);
    setThree(false);
    setFour(false);
    setFive(false);
    setOpenFilter(false);
    setId();
    setPicked(random);
  }

  useEffect(() => {
    fetchData();
  },[picked])
  
  return (
    <div>
        <Stack direction={'column'}>
            <Typography sx={{
                fontWeight: 'bold',
                fontSize: '30px',
                margin: 2
            }}>
                AGENTS COMP
            </Typography>

            <Button onClick={() => {
                rando();
            }}>
                {picked.length > 0 ? 'Re-Pick' : 'Pick Those Agents'}
            </Button>

            {picked.length > 0 ? (
                <Stack sx={{justifyContent: 'space-evenly', flexDirection: {md: 'row', xs: 'column'}}}>
                    <Stack direction={'column'} sx={{alignItems: 'center', marginBottom: {xs: 2, md: 0}}}>
                        <Grid
                            style={{
                                backgroundImage: revealOne
                                    ? `url(${picked[0].bustPortrait})`
                                    : 'url(https://img.freepik.com/free-vector/question-mark-sign-brush-stroke-trash-style-typography-vector_53876-140880.jpg?t=st=1710589046~exp=1710592646~hmac=171f08483909ba1a3f9b978b5b37a582ffed3a0ecb74b677d97c1e7bf6c32559&w=740)',
                                width: '150px',
                                height: '200px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                transition: 'ease-in-out 0.6s',
                                borderRadius: 3,
                                boxShadow: 5,
                            }}
                            onClick={() => {
                                setOne(true);
                            }}
                        />
                        <Button onClick={() => {
                            setId(picked[0]);
                            setOpenFilter(true);
                        }} sx={{
                            display: revealOne ? 'block' : 'none'
                        }}>
                            {picked[0].displayName}
                        </Button>
                    </Stack>

                    <Stack direction={'column'} sx={{alignItems: 'center', marginBottom: {xs: 2, md: 0}}}>
                        <Grid
                            style={{
                                backgroundImage: revealTwo
                                    ? `url(${picked[1].bustPortrait})`
                                    : 'url(https://img.freepik.com/free-vector/question-mark-sign-brush-stroke-trash-style-typography-vector_53876-140880.jpg?t=st=1710589046~exp=1710592646~hmac=171f08483909ba1a3f9b978b5b37a582ffed3a0ecb74b677d97c1e7bf6c32559&w=740)',
                                width: '150px',
                                height: '200px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                transition: 'ease-in-out 0.6s',
                                borderRadius: 3,
                                boxShadow: 5,
                            }}
                            onClick={() => {
                                setTwo(true);
                            }}
                        />
                        <Button onClick={() => {
                            setId(picked[1]);
                            setOpenFilter(true);
                        }} sx={{
                            display: revealTwo ? 'block' : 'none'
                        }}>
                            {picked[1].displayName}
                        </Button>
                    </Stack>

                    <Stack direction={'column'} sx={{alignItems: 'center', marginBottom: {xs: 2, md: 0}}}>
                        <Grid
                            style={{
                                backgroundImage: revealThree
                                    ? `url(${picked[2].bustPortrait})`
                                    : 'url(https://img.freepik.com/free-vector/question-mark-sign-brush-stroke-trash-style-typography-vector_53876-140880.jpg?t=st=1710589046~exp=1710592646~hmac=171f08483909ba1a3f9b978b5b37a582ffed3a0ecb74b677d97c1e7bf6c32559&w=740)',
                                width: '150px',
                                height: '200px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                transition: 'ease-in-out 0.6s',
                                borderRadius: 3,
                                boxShadow: 5,
                            }}
                            onClick={() => {
                                setThree(true);
                            }}
                        />
                        <Button onClick={() => {
                            setId(picked[2]);
                            setOpenFilter(true);
                        }} sx={{
                            display: revealThree ? 'block' : 'none'
                        }}>
                            {picked[2].displayName}
                        </Button>
                    </Stack>

                    <Stack direction={'column'} sx={{alignItems: 'center', marginBottom: {xs: 2, md: 0}}}>
                        <Grid
                            style={{
                                backgroundImage: revealFour
                                    ? `url(${picked[3].bustPortrait})`
                                    : 'url(https://img.freepik.com/free-vector/question-mark-sign-brush-stroke-trash-style-typography-vector_53876-140880.jpg?t=st=1710589046~exp=1710592646~hmac=171f08483909ba1a3f9b978b5b37a582ffed3a0ecb74b677d97c1e7bf6c32559&w=740)',
                                width: '150px',
                                height: '200px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                transition: 'ease-in-out 0.6s',
                                borderRadius: 3,
                                boxShadow: 5,
                            }}
                            onClick={() => {
                                setFour(true);
                            }}
                        />
                        <Button onClick={() => {
                            setId(picked[3]);
                            setOpenFilter(true);
                        }} sx={{
                            display: revealFour ? 'block' : 'none'
                        }}>
                            {picked[3].displayName}
                        </Button>
                    </Stack>

                    <Stack direction={'column'} sx={{alignItems: 'center', marginBottom: {xs: 2, md: 0}}}>
                        <Grid
                            style={{
                                backgroundImage: revealFive
                                    ? `url(${picked[4].bustPortrait})`
                                    : 'url(https://img.freepik.com/free-vector/question-mark-sign-brush-stroke-trash-style-typography-vector_53876-140880.jpg?t=st=1710589046~exp=1710592646~hmac=171f08483909ba1a3f9b978b5b37a582ffed3a0ecb74b677d97c1e7bf6c32559&w=740)',
                                width: '150px',
                                height: '200px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                transition: 'ease-in-out 0.6s',
                                borderRadius: 3,
                                boxShadow: 5,
                            }}
                            onClick={() => {
                                setFive(true);
                            }}
                        />
                        <Button  onClick={() => {
                            setId(picked[4]);
                            setOpenFilter(true);
                        }} sx={{
                            display: revealFive ? 'block' : 'none'
                        }}>
                            {picked[4].displayName}
                        </Button>
                    </Stack>

                    <Dialog
                        open={openFilter}
                        onClose={() => setOpenFilter(false)}
                        aria-labelledby="filter-dialog"
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle id="filter-dialog">
                            <Stack direction={'row'} sx={{
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}>
                                {id?.displayName}
                                <IconButton
                                    onClick={() => {setOpenFilter(false)}}
                                    component='label'
                                    sx={{
                                        color: 'black',
                                        borderRadius: '50%',
                                        opacity: 1,
                                        '&:hover': {
                                            opacity: 1,
                                            color: 'gray',
                                            transform: 'scale(1.0)',
                                        },
                                    }}
                                    >
                                    <CloseIcon sx={{fontSize: '20px'}} />
                                </IconButton>
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            <Stack spacing={2}>
                                <Stack spacing={1} direction={'row'} sx={{alignItems: 'center'}}>
                                    <img src={id?.role.displayIcon} alt="" title={id?.role.description} width='25px' height='25px' style={{backgroundColor: 'gray', borderRadius: '50%'}} />
                                    <Typography sx={{
                                        fontWeight: 'bold',
                                        fontSize: '18px'
                                    }}>
                                        {id?.role.displayName}
                                    </Typography>
                                </Stack>
                                
                                <Typography>
                                    "{id?.description}"
                                </Typography>

                                <Typography sx={{
                                    fontWeight: 'bold'
                                }}>
                                    Abilities : 
                                </Typography>
                                <Stack sx={{ flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'space-evenly'}}>
                                    <Stack direction={'column'} sx={{alignItems: 'center'}}>
                                        <img src={id?.abilities[0].displayIcon} alt="" title={id?.abilities[0].description} width='100px' height='100px' style={{backgroundColor: 'gray', borderRadius: '50%'}} />
                                        <Typography>
                                            {id?.abilities[0].displayName}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={'column'} sx={{alignItems: 'center'}}>
                                        <img src={id?.abilities[1].displayIcon} alt="" title={id?.abilities[1].description} width='100px' height='100px' style={{backgroundColor: 'gray', borderRadius: '50%'}} />
                                        <Typography>
                                            {id?.abilities[1].displayName}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={'column'} sx={{alignItems: 'center'}}>
                                        <img src={id?.abilities[2].displayIcon} alt="" title={id?.abilities[2].description} width='100px' height='100px' style={{backgroundColor: 'gray', borderRadius: '50%'}} />
                                        <Typography>
                                            {id?.abilities[2].displayName}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={'column'} sx={{alignItems: 'center'}}>
                                        <img src={id?.abilities[2].displayIcon} alt="" title={id?.abilities[2].description} width='100px' height='100px' style={{backgroundColor: 'gray', borderRadius: '50%'}} />
                                        <Typography>
                                            {id?.abilities[2].displayName}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                sx={{backgroundColor: 'cancel', fontSize: {md: '14px', xs: '12px'}, ':hover': {backgroundColor: 'cancelhover'}}}
                                onClick={() => {
                                    setOpenFilter(false);
                                }}
                            >
                                Back
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Stack>
            ) : (
                <></>
            )}
        </Stack>
    </div>
  )
};

export default Picker;
